import React from "react";

// @ts-ignore
import magnet from "../../../public/img/magnet.png";
// @ts-ignore
import gower from "../../../public/img/gower.png";
// @ts-ignore
import commodore from "../../../public/img/commodore.png";
// @ts-ignore
import cie from "../../../public/img/cie.png";

export function PageFooter() {
  return (
    <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder mt-6">
      <div className="p-4 space-y-4">
        <p className="text-secondary">
          <small>
            This survey is managed and supported by the external research partner, George Naylor Associates Ltd. on
            behalf of the following Nobia companies.
          </small>
        </p>
        <div className="flex flex-col sm:flex-row sm:justify-between bg-success p-4 items-center flex-wrap gap-4">
          <div className="w-36">
            <img alt="Magnet logo" src={magnet} className="relative top-0.5"  />
          </div>
          <div className="w-32">
            <img alt="Gower logo" src={gower}  />
          </div>
          <div className="">
            <img alt="Commodore Kitchens logo" src={commodore} />
          </div>
          <div className="relative top-1">
            <img alt="CIE logo" src={cie} />
          </div>
        </div>
      </div>
    </div>
  );
}
