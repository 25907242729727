import { SurveyItem, SurveyPage } from "./entity";

/**
 * Run 'lambda' on SurveyItem, and every child SurveyItem should it have any
 */
export function applyToItemAndChildren(lambda: (item: SurveyItem) => void, item: SurveyItem) {
  // Run the user-supplied lambda
  lambda(item);

  // Recurse if necessary
  if (item.type === "conditional") {
    for (const innerItem of item.surveyItems) {
      applyToItemAndChildren(lambda, innerItem);
    }
  }
}

/**
 * Recursively applies the supplied function to every SurveyItem in 'surveyPages'
 *
 * @param lambda The function to apply to each SurveyItem
 * @param surveyPages The SurveyPages to apply the function to
 * @param pageLambda An optional function to apply to each SurveyPage
 */
export function applyToAllItems(
  lambda: (item: SurveyItem) => void,
  surveyPages: SurveyPage[],
  pageLambda?: (page: SurveyPage) => void,
) {
  for (const page of surveyPages) {
    if (pageLambda) pageLambda(page);
    for (const item of page.surveyItems) {
      applyToItemAndChildren(lambda, item);
    }
  }
}

/**
 * Recursively applies the supplied function to every SurveyItem in 'surveyPages'
 *
 * @param lambda The function to apply to each SurveyItem
 * @param surveyPages The SurveyPages to apply the function to
 */
export async function applyToAllItemsAsync(lambda: (item: SurveyItem) => Promise<void>, surveyPages: SurveyPage[]) {
  async function applyToItemAndChildrenAsync(lambda: (item: SurveyItem) => Promise<void>, item: SurveyItem) {
    // Run the user-supplied lambda
    await lambda(item);

    // Recurse if necessary
    if (item.type === "conditional") {
      for (const innerItem of item.surveyItems) {
        await applyToItemAndChildrenAsync(lambda, innerItem);
      }
    }
  }

  for (const page of surveyPages) {
    for (const item of page.surveyItems) {
      await applyToItemAndChildrenAsync(lambda, item);
    }
  }
}
