import { RespondentDefinition } from "./entity";

/**
 * Provides a lookup for respondent attributes
 */
export class RespondentHelper {
  private emailColumnNames: Array<string> = [];

  constructor(respondentDefinition: RespondentDefinition) {
    respondentDefinition.attributes.forEach((attribute) => {
      if (attribute.mapping === "email") {
        this.emailColumnNames.push(attribute.attributeName);
      }
    });
  }

  /**
   * Get the email address column names
   */
  getEmailColumnNames() {
    return this.emailColumnNames;
  }
}
