import React from "react";
import { QuestionLabel, shuffle, SurveyContext } from "@careerinsight/applib-react";
import { RatingSet } from "@careerinsight/nobia-project-layer";

export const RatingSetWidget: React.FC<{ question: RatingSet }> = ({ question }) => {
  const { answers, setAnswer } = React.useContext(SurveyContext);

  // Compile existing answers
  const nextState = new Set<string>();
  const existingAnswers = answers[question.questionKey];
  if (Array.isArray(existingAnswers) && existingAnswers.length) {
    for (const v of existingAnswers) nextState.add(v);
  }
  const [state, setState] = React.useState<Set<string>>(nextState);

  const options = React.useMemo(() => {
    return question.randomize ? shuffle(question.titleMap) : [...question.titleMap];
  }, []);

  const selectOption = (value: string) => {
    const nextState = new Set(state);
    if (nextState.has(value)) nextState.delete(value);
    else if (nextState.size < question.maxChoices) nextState.add(value);
    setState(nextState);
    setAnswer(question, Array.from(nextState));
  };

  return (
    <li className="block">
      <QuestionLabel question={question} />

      <div className="border border-info-border rounded-md mb-5 p-2 text-info-dark bg-info-light text-sm flex">
        <div className="self-center">
          <svg id="info-outline" viewBox="-1 -1 18 18" width="22px">
            <path d="m8.93 6.59-1 4.7c-.07.34.03.54.3.54.2 0 .5-.07.69-.25l-.09.42c-.29.34-.92.6-1.46.6-.7 0-1-.43-.81-1.32L7.3 7.8c.06-.3 0-.4-.29-.47l-.45-.08.08-.38 2.3-.3ZM8 5.5c-1.33 0-1.33-2 0-2s1.33 2 0 2Z" />
            <ellipse cx="8" cy="8.01" fill="none" stroke="currentColor" strokeWidth="1" rx="7.85" ry="7.86" />
          </svg>
        </div>
        <div className="self-center ps-2">
          You may choose up to 3 factors by clicking the labels below. Click the label a second time to deselect.
        </div>
      </div>

      <div className="rounded bg-secondary-light mt-3 p-2 ring-1 ring-secondary/30">
        {options.map((option) => (
          <button
            className={`
							 py-2 px-4 border border-secondary-border rounded m-1 text-sm
							${!state.has(option.value) ? "bg-light hover:brightness-[80%] border-secondary " : ""}
							${state.has(option.value) ? "bg-primary text-light hover:brightness-[80%] border-primary " : ""}
						`}
            type="button"
            key={option.value}
            onClick={() => selectOption(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </li>
  );
};
